import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';
import { useDrugScreenInfo } from './useDrugScreenInfo';
import { DRUG_SCREEN_STATUS } from '../components/steps/dsCheck/util';



export const useVJOSteps = () => {

    const { t } = useTranslation();
    const { drugScreenRequiredResponse } = useDrugScreenInfo();

    const handleStepsUpdate = () => {
        let steps = (drugScreenRequiredResponse?.drugScreenRequired || drugScreenRequiredResponse?.drugScreenStatus > DRUG_SCREEN_STATUS.NOT_STARTED) ? t('vjoDashboard.pages', {returnObjects: true}) : t('vjoDashboard.pagesWithoutDS', {returnObjects: true});
        if(cookie.load('isCpPorterDsPilot') === 'true' &&
            drugScreenRequiredResponse?.drugScreenRequired === null &&
            !drugScreenRequiredResponse?.fullSsnAvailable
        ){
            steps = t('vjoDashboard.pagesWithOptionalDS', {returnObjects: true});
        }
        return steps;
    }

    return { handleStepsUpdate };
}