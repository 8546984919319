import React, { useEffect, useState } from 'react';
import style from './EditProfileJobList.module.css';
import {
	updateJobIndex,
	updateEditJob,
	updateAllEmployers,
	updateJobHistory,
	resetJobHistory,
	resetAllEmployers
} from '../../../../redux/JobHistory/slice';
import {
	updateLoading,
	subHeader,
	executionErrorOccured
} from '../../../../redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getEmplHistory, deleteJobEntry } from '../../../../redux/JobHistory/request';
import { callHiringHelper } from '../../../../redux/HiringHelper/request';
import {
	Body,
	Button,
	Card,
	Divider,
	Modal,
	BottomSheet
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import JobExpModal from '../../JobHistory/JobExpModal';
import * as moment from 'moment';
import 'moment/min/locales.min';
import { useDisplay } from 'utils/useDisplay';
import AlertDialog from 'components/AlertDialog';

const EditProfileJobList = () => {
	const global = useSelector((state) => state.global);
	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';
	const { t } = useTranslation();
	const jobHistory = useSelector((state) => state.jobHistory);
	const account = useSelector((state) => state.account);
	const [editJobData, setEditJobData] = useState(false);
	const [jobList, setjobList] = useState([]);
	const dispatch = useDispatch();
	const [isOpenExp, setisOpenExp] = useState(false);
	const [remove, setRemove] = useState(false);
	const [removeJobIndex, setRemoveJobIndex] = useState(0);
	const { isPortrait } = useDisplay();
	const[hasEnteredWalmartExp, setHasEnteredWalmartExp] = useState(true);
	moment.locale(global.language === 'ES' ? 'es' : 'en');
	const jobHistoryRemove = 'jobHistory.remove';

	useEffect(() => {
		dispatch(
			subHeader({
				title: 'editProfile.jobHistory',
				routeTo: 'edit-profile',
				display: 'true'
			})
		);
		getEmploymentHistory();

		// eslint-disable-next-line
	}, []);
	const getEmploymentHistory = () => {
		const requestInput = {
			applicantId: global.applicantId
		};

		dispatch(resetJobHistory({ allEmployers: [] }));
		dispatch(resetAllEmployers({ allEmployers: [] }));
		dispatch(updateLoading(true));
		dispatch(getEmplHistory(requestInput))
			.then((res) => {
				const data = res.payload;
				dispatch(updateAllEmployers({ allEmployers: data.allEmployers }));
				if (data.allEmployers.length !== 0) {
					let empHistList = [];
					data.allEmployers.forEach((obj) => {
						const list = {
							companyName: obj.company_name,
							position: obj.allPositions[0].job_position_desc,
							industry: obj.industry_catg_code,
							category: obj.allPositions[0].maj_work_grp_code,
							subCategory: obj.allPositions[0].min_work_grp_code,
							startDate: obj.allPositions[0].pos_start_date,
							endDate: obj.allPositions[0].pos_end_date,
							reason: obj.left_reason_text,
							employer_seq_nbr: obj.employer_seq_nbr
						};
						dispatch(updateJobHistory({ jobDetail: list }));
						empHistList.push(list);
					});
					setjobList(empHistList);
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleAddjob = () => {
		setEditJobData(false);
		setisOpenExp(false);
		getEmploymentHistory();
	};
	const handleAddnewExp = () => {
		setEditJobData(false);
		setisOpenExp(true);
	};

	const handleEditJob = (jobDetail) => {
		setEditJobData(false);
		setisOpenExp(false);
		getEmploymentHistory();
	};

	const editJob = (index) => {
		dispatch(updateJobIndex({ jobIndex: index }));
		dispatch(updateEditJob({ editJob: true }));
		setEditJobData(true);
		setisOpenExp(true);
	};

	const removeJob = (index) => {
		const deleteJob = jobList[index];
		const request = {
			allEmployers: [
				{
					employer_seq_nbr: deleteJob.employer_seq_nbr,
					industry_catg_code: deleteJob.industry,
					last_pay_rate_amt: '',
					company_name: deleteJob.companyName,
					old_company_name: deleteJob.companyName,
					address_line_1: '',
					address_line_2: null,
					city_name: '',
					state_prov_code: '',
					country_code: countryCode,
					postal_code: '',
					phone_nbr: '',
					supvr_first_name: null,
					supvr_last_name: null,
					contact_allow_ind: 'Y',
					left_reason_text: '',
					empl_start_date: null,
					empl_end_date: null,
					positionHistory: {
						job_postn_seq_nbr: '1',
						job_position_desc: deleteJob.position,
						old_job_position_desc: deleteJob.position,
						pos_start_date: deleteJob.startDate,
						pos_end_date: deleteJob.endDate,
						maj_work_grp_code: deleteJob.category,
						min_work_grp_code: deleteJob.subCategory
					},
					allPositions: [
						{
							job_postn_seq_nbr: '1',
							job_position_desc: deleteJob.position,
							old_job_position_desc: deleteJob.position,
							pos_start_date: deleteJob.startDate,
							pos_end_date: deleteJob.endDate,
							maj_work_grp_code: deleteJob.category,
							min_work_grp_code: deleteJob.subCategory
						}
					]
				}
			],
			applicant_id: global.applicantId
		};

		dispatch(updateLoading(true));
		dispatch(deleteJobEntry(request))
			.then((res) => {
				const data = res.payload;
				if (data === true) {
					if (index !== -1) {
						jobList.splice(index, 1);
					}
					if (account?.data?.auth?.stepNbr >= 850) {
						dispatch(callHiringHelper(global.applicantId));
					}
					getEmploymentHistory();
					setRemove(false);
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleExpModal = (value) => {
		setEditJobData(false);
		dispatch(updateEditJob({ editJob: false }));
		setisOpenExp(value);
	};

	const removeJobModal = (index) => {
		setRemove(true);
		setRemoveJobIndex(index);
	};
	return (
		<div className={style.main}>
			{console.log(isPortrait)}
			<div className={style.container}>
				<div className={style.jobHistoryText}>
					<Body as="p" size="small" weight={400}>
						{t('jobHistory.historyText1')}
					</Body>
					<br />
					<Body as="p" size="small" weight={400}>
						{t('jobHistory.historyText2')}
					</Body>
				</div>
				<AlertDialog
											isOpen={hasEnteredWalmartExp}
											// onClose={handleClose}
											title={t('location.notice')}
											children={
											<Body>
												{t('aboutYouPersonalInfo.previousRecord')}<br/>
												{t('aboutYouPersonalInfo.walmartLocation')} 126<br/>
												{t('aboutYouPersonalInfo.confirmRecord')}<br/>
											</Body>}
											actions={
												<div className={style.continue}>
													<Button
														// onClick={handleClose}
														size="medium"
														variant="primary"
													>
														{t('general.deny')}
													</Button>
												</div>
											}
											>
												actions={
												<div className={style.continue}>
													<Button
														// onClick={handleClose}
														size="medium"
														variant="primary"
													>
														{t('general.confirm')}
													</Button>
												</div>
											}
											>
											<Body as="div" size="medium" weight={400}>
												{t('aboutYouPersonalInfo.preEmploymentText')}
											</Body>				
										</AlertDialog>
				{jobList.length !== 0
					? jobList.map((item, index) => {
							return (
								<div style={{ margin: '16px' }} key={index}>
									<Card size="small" UNSAFE_className={style.card}>
										<div style={{ width: '100%' }}>
											<div style={{ float: 'left' }}>
												<Body as="p" size="medium" weight={700}>
													{item.position}
												</Body>
											</div>
											<div className={isPortrait ? style.mobileEdit : style.desktopEdit}>
												<div style={{ float: 'left' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => editJob(index)}>
														{t('jobHistory.edit')}
													</Button>
												</div>

												<div style={{ float: 'right' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => removeJobModal(index)}>
														{t(jobHistoryRemove)}
													</Button>
												</div>
											</div>
										</div>
										<div style={{ width: '100%' }}>
											<img
												src={require('../../../../assets/images/companyIcon.png')}
												alt="Company icon"
											/>
											<div style={{ width: '90%', display: 'inline-block', paddingLeft: '10px' }}>
												<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#2E2F32' }}>
													{item.companyName}
												</Body>
											</div>
										</div>
										<div style={{ width: '100%' }}>
											<img
												src={require('../../../../assets/images/calenderIcon.png')}
												alt="Work duration"
											/>
											<div style={{ width: '90%', display: 'inline-block', paddingLeft: '10px' }}>
												<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#2E2F32' }}>
													{moment(item.startDate, 'MM/YYYY', true).format('MMMM YYYY')}
													{' - '}
													{moment(item.endDate, 'MM/YYYY', true).isValid()
														? moment(item.endDate, 'MM/YYYY', true).format('MMMM YYYY')
														: moment().format('MMMM YYYY')}
												</Body>
											</div>
										</div>
									</Card>
								</div>
							);
					  })
					: ''}

				<div style={{ padding: '16px' }}>
					<Button size="medium" variant="secondary" onClick={handleAddnewExp}>
						{t('jobHistory.add')}
					</Button>
				</div>
				<JobExpModal
					isOpenExp={isOpenExp}
					handleExpModal={handleExpModal}
					handleAddjob={handleAddjob}
					handleEditJob={handleEditJob}
					jobList={jobList}
					editJobData={editJobData}
				/>

				{!isPortrait && (
					<Modal
						isOpen={remove}
						onClose={() => setRemove(false)}
						title={t('jobHistory.removeJob')}
						actions={
							<Button
								size="medium"
								variant="primary"
								isFullWidth
								onClick={() => removeJob(removeJobIndex)}>
								{t(jobHistoryRemove)}
							</Button>
						}>
						<Body as="p" size="medium" weight={400}>
							{t('jobHistory.removeJobMessage')}
						</Body>
					</Modal>
				)}

				{isPortrait && (
					<BottomSheet
						isOpen={remove}
						onClose={() => setRemove(false)}
						title={t('jobHistory.removeJob')}
						actions={
							<Button
								size="medium"
								variant="primary"
								isFullWidth
								onClick={() => removeJob(removeJobIndex)}>
								{t(jobHistoryRemove)}
							</Button>
						}>
						<Body as="p" size="medium" weight={400}>
							{t('jobHistory.removeJobMessage')}
						</Body>
					</BottomSheet>
				)}
			</div>
		</div>
	);
};

export default EditProfileJobList;
