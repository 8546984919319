import React, { memo, useEffect, useMemo } from 'react';
import { VJOHomeScreen } from './components/landing';
import VJODashboard from './components/dashboard';
import { useVirtualJobOffer } from './hooks/useVirtualJobOffer';
import { useDrugScreenInfo } from './hooks/useDrugScreenInfo';
import { useNavigate } from 'react-router-dom';
import { STEPS } from './util';

/**
 * Virtual Job Offer Container
 */
const VirtualJobOfferContainer = () => {
	const navigate = useNavigate();
	const { fetchVirtualJobOffer, nextStepInd, jobOffer } = useVirtualJobOffer();
	const { drugScreenRequiredResponse, getVJOisDrugScreenRequiredResponse } = useDrugScreenInfo();

	useEffect(() => {
		if (!jobOffer) {
			fetchVirtualJobOffer();
		}
	}, [fetchVirtualJobOffer, jobOffer]);

	useEffect(() => {
		if (typeof drugScreenRequiredResponse?.drugScreenRequired != 'boolean' && typeof drugScreenRequiredResponse?.fullSsnAvailable != 'boolean') {
			getVJOisDrugScreenRequiredResponse();
		}
	}, [drugScreenRequiredResponse?.drugScreenRequired]);

	useEffect(() => {
		if (typeof drugScreenRequiredResponse?.drugScreenRequired === 'boolean' || typeof drugScreenRequiredResponse?.fullSsnAvailable === 'boolean') {
			if (
				(nextStepInd >= STEPS.CONGRATULATIONS_WITHOUT_DS_REQUIRED + 1 &&
					!drugScreenRequiredResponse?.drugScreenRequired) ||
				(nextStepInd >= STEPS.CONGRATULATIONS_WITH_DS_REQUIRED + 1 &&
					drugScreenRequiredResponse?.drugScreenRequired)
			) {
				navigate('../vjo-steps', {
					state: {
						step: nextStepInd,
						activePageFromDashboard: nextStepInd,
						isDrugScreenRequired: drugScreenRequiredResponse?.drugScreenRequired,
						isFromLoginPage: true,
						fullSsnAvailable: drugScreenRequiredResponse?.fullSsnAvailable,
						drugScreenStatus: drugScreenRequiredResponse?.drugScreenStatus
					}
				});
			}
		}
	}, [drugScreenRequiredResponse?.drugScreenRequired]);

	return useMemo(() => {
		if (
			nextStepInd > 0 &&
			((nextStepInd < STEPS.CONGRATULATIONS_WITHOUT_DS_REQUIRED + 1 &&
				!drugScreenRequiredResponse?.drugScreenRequired) ||
				(nextStepInd < STEPS.CONGRATULATIONS_WITH_DS_REQUIRED + 1 &&
					drugScreenRequiredResponse?.drugScreenRequired))
		) {
			return <VJODashboard />;
		} else if (nextStepInd === 0) {
			return <VJOHomeScreen />;
		}
		return <></>;
	}, [nextStepInd, drugScreenRequiredResponse?.drugScreenRequired]);
};

export default memo(VirtualJobOfferContainer);
