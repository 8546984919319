import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import ContactInfo from '../contactInfo';
import ProgressTrackerComp from '../progressTrackerComp';
import Availability from '../availability';
import Review from '../review';
import AcknowledgeOffer from '../acknowledge';
import { useLocation } from 'react-router-dom';
import StepHeader from './StepHeader';
import Congratulations from '../congratulations/Congratulations';
import BackgroundCheck from '../backgroundCheck';
import DrugScreenCheck from '../dsCheck';
import { PAGES, TOTAL_STEPS_WITH_DS, TOTAL_STEPS_WITHOUT_DS } from '../../../util';
import { useDeclineOffer } from '../../declineOffer/useDeclineOffer';
import DeclineMessage from '../../declineOffer/DeclineMessage';
import DeclineOffer from '../../declineOffer';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';
import { DRUG_SCREEN_STATUS } from '../dsCheck/util';
import cookie from 'react-cookies';


export const StepContainer = () => {
	const { state } = useLocation();
	const { onDeclineOffer, isOfferDeclined } = useDeclineOffer();
	const { drugScreenRequiredResponse } = useDrugScreenInfo();

	const [currentStep, setCurrentStep] = useState(state?.step);
	const [stepStatus, setStepStatus] = useState(
		typeof state?.isDrugScreenRequired === 'boolean' && !(state?.isDrugScreenRequired ||  state?.drugScreenStatus > DRUG_SCREEN_STATUS.NOT_STARTED)
			? { 1: false, 2: false, 3: false, 4: false, 5: false } 
			: { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
	);
	const [isDeclineClicked, setDeclineClick] = useState(false);
	const [totalPages, setTotalPages] = useState(typeof state?.isDrugScreenRequired === 'boolean' && !(state?.isDrugScreenRequired ||  state?.drugScreenStatus > DRUG_SCREEN_STATUS.NOT_STARTED) ? TOTAL_STEPS_WITHOUT_DS : TOTAL_STEPS_WITH_DS);
	useEffect(() => {
		Object.keys(stepStatus).forEach((key) => {
			if (key < state?.activePageFromDashboard) {
				stepStatus[key] = true;
			}
		});
		setStepStatus({ ...stepStatus});
	}, [totalPages]);

	useEffect(() => {
		if(
			cookie.load('isCpPorterDsPilot') === 'true' &&
			typeof drugScreenRequiredResponse?.drugScreenRequired === 'boolean' &&
			!(drugScreenRequiredResponse?.drugScreenRequired ||
				drugScreenRequiredResponse?.drugScreenStatus > DRUG_SCREEN_STATUS.NOT_STARTED) && 
			Object.keys(stepStatus).length == TOTAL_STEPS_WITH_DS
		) {
			delete stepStatus[TOTAL_STEPS_WITH_DS];
			setStepStatus({...stepStatus});
			setTotalPages(TOTAL_STEPS_WITHOUT_DS);
			
		}

	},[drugScreenRequiredResponse]);

	const handleChangeNextStep = () => {
		setCurrentStep(currentStep + 1);
	};
	const handleChangePreviousStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const handleChangeCurrentStepStatus = (val) => {
		stepStatus[currentStep] = val;
		setStepStatus({ ...stepStatus });
	};

	// show Decline offer
	const showDeclineOffer = useCallback(() => {
		setDeclineClick(true);
	}, []);
	// hide Decline offer
	const hideDeclineOffer = useCallback(() => {
		setDeclineClick(false);
	}, []);
	// decline offer
	const declineOffer = useCallback((reasonCode) => {
		onDeclineOffer(reasonCode);
	});

	const StepView = () => {
		if (totalPages === TOTAL_STEPS_WITH_DS) {
			switch (currentStep) {
				case PAGES.CONTACT_INFO:
					return (
						<ContactInfo
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
							declineOffer={declineOffer}
						/>
					);
				case PAGES.AVAILABILITY:
					return <Availability handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.REVIEW:
					return <Review handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.ACKNOWLEDGE:
					return (
						<AcknowledgeOffer
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
						/>
					);
				case PAGES.DRUG_SCREEN_CHECK:
					return (
						<DrugScreenCheck
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
						/>
					);
				case PAGES.BACKGROUND_CHECK:
					return <BackgroundCheck handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				default:
					return <Congratulations />;
			}
		} else if (totalPages === TOTAL_STEPS_WITHOUT_DS) {
			switch (currentStep) {
				case PAGES.CONTACT_INFO:
					return (
						<ContactInfo
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
							declineOffer={declineOffer}
						/>
					);
				case PAGES.AVAILABILITY:
					return <Availability handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.REVIEW:
					return <Review handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.ACKNOWLEDGE:
					return (
						<AcknowledgeOffer
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
						/>
					);
				case PAGES.BACKGROUND_CHECK - 1:
					return <BackgroundCheck handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				default:
					return <Congratulations />;
			}
		}
	}

	if (isOfferDeclined) {
		return <DeclineMessage />;
	}

	return (
		<React.Fragment>
			{currentStep > 0 && currentStep <= 10 && (
				<div className="vjo_steps_main_container">
					<div className="vjo_steps_main_content_container">
						<div className="vjo_steps_header_container">
							<div className="vjo_steps_header">
								<StepHeader currentStep={currentStep} />
							</div>
						</div>
						{StepView()}
					</div>
					<ProgressTrackerComp
						currentStep={currentStep}
						stepStatus={stepStatus}
						handleChangeNextStep={handleChangeNextStep}
						handleChangePreviousStep={handleChangePreviousStep}
						showDeclineOffer={showDeclineOffer}
					/>
				</div>
			)}
			<DeclineOffer isOpen={isDeclineClicked} onClose={hideDeclineOffer} />
		</React.Fragment>
	);
};

export default StepContainer;
