import React, { useState, useEffect, useRef } from 'react';
import {
	TextField,
	Select,
	Body,
	Checkbox,
	Button,
	FormGroup
} from '@walmart-web/livingdesign-components';
import cookie from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import style from './AboutYouPersonalInfo.module.css';
import commonStyles from '../../../../Style/commonStyle.module.css';
import {
	updateFirstName,
	updateLasttName,
	updateSSN,
	updatePersonalInfo,
	updateCheckHire
} from '../../../../redux/AboutYou/slice';
import { Check, Eye, EyeSlash } from '@livingdesign/icons';
import * as moment from 'moment';
import 'moment/min/locales.min';
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import {
	updateLoading,
	onLogOut,
	executionErrorOccured
} from '../../../../redux/slices/globalSlice';
import {
	getPersonalInfo,
	aboutApplicantStep1,
	checkRehire
} from '../../../../redux/AboutYou/request';
import PreEmployMentModal from './PreEmploymentModal';
import ExitModal from './ExitModal';
import { useDisplay } from '../../../../utils/useDisplay';
import AlertDialog from 'components/AlertDialog';

const AboutYouPersonalInfo = (props) => {
	const { t } = useTranslation();
	const { isPortrait } = useDisplay();
	const dispatch = useDispatch();
	const [firstName, setFirstName] = useState('');
	const [middleInitial, setMiddleInitial] = useState('');
	const [lastName, setLastName] = useState('');
	const [prefferedFirstName, setprefferedFirstName] = useState('');
	const [prefferedLastName, setprefferedLastName] = useState('');

	const [zipCode, setZipCode] = useState('');
	const [checkBox, setCheckBox] = useState(false);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';
	const [success, setSuccess] = useState(false);
	const [partialSSN1, setPartialSSN1] = useState('');
	const [partialSSN2, setPartialSSN2] = useState('');
	const [ssnDisabled, setSSnDisabled] = useState(false);
	const [maskSsn, setMaskSsn] = useState(true);
	const [birthMonth, setBirthMonth] = useState(0);
	const [daysInMonth, setDaysInMonth] = useState([]);
	const [birthDate, setBirthDate] = useState(0);
	const [zipError, setZipError] = useState('');
	const [rehireStatus, setRehireStatus] = useState(false);
	const aboutYou = useSelector((state) => state.aboutYou);
	const ssn2Ref = useRef();
	const ssn1Ref = useRef();
	const [exit, setExit] = useState(false);
	const [ssnDisclaimerPopUp, setSsnDisclaimerPopUp] = useState(false);
	const [exitText, setExitText] = useState('');
	const [error, setError] = React.useState({});
	const [potentialMatchStoreNbr , setPotentailMatchStoreNbr] = useState(account.data.auth.potentialMatchStoreNbr);
	const postObj = {
		agree: false,
		agreeDis: true,
		applStatusCode: 300,
		applicantId: global.applicantId,
		birthDate: birthDate,
		birthMonth: birthMonth,
		checkRehire: aboutYou.aboutYouReq.checkRehire,
		countryCode: countryCode,
		firstName: firstName,
		formerAssociate: false,
		identificationNbr: '',
		isChanged: true,
		lastName: lastName,
		preferredFirstName: prefferedFirstName,
		preferredLastName: prefferedLastName,
		middleName: middleInitial,
		partSsnNbr: partialSSN1 + partialSSN2,
		partialDob: '' + birthMonth + birthDate,
		valid: true,
		zipCode: zipCode
	};

	const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'].*$/;
	const handleFirstName = (e) => {
		setSuccess(false);
		let value = e.target.value;
		if (value === '') {
			setFirstName('');
		}
		value = removeInvalidChars(value);

		setFirstName(value.slice(0, 15));
	};

	const handleMiddleInitial = (e) => {
		setSuccess(false);
		let value = e.target.value;
		if (value === '') {
			setMiddleInitial('');
		}
		value = removeInvalidChars(value);

		setMiddleInitial(value.slice(0, 1));
	};

	const handleLastName = (e) => {
		setSuccess(false);
		let value = e.target.value;
		if (value === '') {
			setLastName('');
		}
		value = removeInvalidChars(value);

		setLastName(value.slice(0, 20));

		dispatch(updateCheckHire({ checkRehire: true }));
	};

	const handlePrefferedFirstName = (e) => {
		setSuccess(false);
		const value = e.target.value;
		if (value === '') {
			setprefferedFirstName('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setprefferedFirstName(removeInvalidChars(value).slice(0, 15));
		}
	};

	const handlePrefferedLastName = (e) => {
		setSuccess(false);
		const value = e.target.value;
		if (value === '') {
			setprefferedLastName('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setprefferedLastName(removeInvalidChars(value).slice(0, 20));
		}
	};

	const handleZipCode = (e) => {
		props.handleStudentScreen(false);
		setZipError('');
		setSuccess(false);
		const regex = /^[0-9]*$/;
		const value = e.target.value;
		if (value === '') {
			setZipCode('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setZipCode(value);
		}
		dispatch(updateCheckHire({ checkRehire: true }));
	};

	const handlessn1 = (e) => {
		setSuccess(false);
		const regex = /^[0-9]*$/;
		const value = e.target.value;
		if (value === '') {
			setPartialSSN1('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setPartialSSN1(value);
			if (value.length >= 2) {
				ssn2Ref.current.focus();
			}
		}
	};

	const handlessn2 = (e) => {
		setSuccess(false);
		const regex = /^[0-9]*$/;
		const value = e.target.value;
		if (value === '') {
			setPartialSSN2('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setPartialSSN2(value);
			if (value.length === 0) {
				ssn1Ref.current.focus();
			}
		}
	};

	const handleCheckBox = (e) => {
		setSuccess(false);
		setCheckBox(!checkBox);
	};

	const handleModal = (value) => {
		setRehireStatus(false);
		setSsnDisclaimerPopUp(false)
	};

	const handleDisclaimer = (e) => {
		if(checkBox &&!ssnDisclaimerPopUp)
			setSsnDisclaimerPopUp(true)
	}

	const handleButton = (e) => {
		setSsnDisclaimerPopUp(false)
		dispatch(updateLoading(true));
		dispatch(aboutApplicantStep1(postObj))
			.then((res) => {
				cookie.save('applicantName', firstName);
				if (res.payload.responseCode === undefined) {
					if (res.payload.response.data.responseCode === '444') {
						setZipError('renew.zipError');
					}
				} else if (res.payload.responseCode === '201' || res.payload.responseCode === '200') {
					setSSnDisabled(true);
					if (res.payload.checkRehire) {
						dispatch(updateLoading(false));
						setRehireStatus(true);
						setPotentailMatchStoreNbr(res.payload.potentialMatchStoreNumber)
					} else {
						setSuccess(true);
						props.handleStudentScreen(true);
						scroll.scrollToBottom();
					}
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const personalInfoComplete = (e) => {
		setSuccess(true);
		props.handleStudentScreen(true);
		scroll.scrollToBottom();
	};

	const removeInvalidChars = (value) => {
		props.handleStudentScreen(false);
		let check = value;
		if (countryCode === 'PR') {
			check = check.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'']/gi, '');
		} else {
			check = check.replace(/[^\w\s\.\-\'\,']/gi, '');
		}
		check = check.replace(/^\s+/g, '');
		check = check.replace(/\d+/g, '');
		check = check.replace(/_/g, '');
		return check.toUpperCase();
	};

	const getDaysInMonth = (month) => {
		return new Date(2024, month, 0).getDate();
	};

	const handleBirthMonth = (e) => {
		props.handleStudentScreen(false);
		setSuccess(false);
		const value = e.target.value;
		const birthMonth = Number(value);
		setBirthMonth(value);
		const days = getDaysInMonth(birthMonth);
		const noOfDays = new Array(days).fill('');
		setDaysInMonth(noOfDays);
		dispatch(updateCheckHire({ checkRehire: true }));
	};

	const handleBirthDay = (e) => {
		props.handleStudentScreen(false);
		setSuccess(false);
		setBirthDate(e.target.value);
		dispatch(updateCheckHire({ checkRehire: true }));
	};

	useEffect(() => {
		getPersonalInfoDetails();
		// eslint-disable-next-line
	}, []);

	const getPersonalInfoDetails = async () => {
		const requestInput = {
			applicantId: global.applicantId,
			emailId: global.emailId
		};
		dispatch(updateLoading(true));
		dispatch(getPersonalInfo(requestInput))
			.then((res) => {
				const data = res.payload;
				dispatch(updatePersonalInfo(data));
				setFirstName(data.firstName);
				setMiddleInitial(data.middleName);
				setLastName(data.lastName);
				if (data.zipCode && data.zipCode.trim() !== '') {
					setZipCode(data.zipCode);
				}
				setprefferedFirstName(data.preferredFirstName);
				setprefferedLastName(data.preferredLastName);
				setZipError('');
				if (data.partSsnNbr && data.partSsnNbr !== '') {
					setPartialSSN1(data.partSsnNbr.slice(0, 2));
					setPartialSSN2(data.partSsnNbr.slice(2));
					setSSnDisabled(true);
					setCheckBox(true);
				} else if (data.identificationNbr && data.identificationNbr !== '') {
					setPartialSSN1(data.identificationNbr.slice(3, 5));
					setPartialSSN2(data.identificationNbr.slice(5, 9));
					setSSnDisabled(true);
					setCheckBox(true);
				}
				let partialDob = data.partialDob;
				if (partialDob.length === 3) {
					partialDob = '0' + partialDob;
				}
				const birthMonthVar = partialDob.slice(0, 2);
				setBirthMonth(birthMonthVar);
				const days = getDaysInMonth(Number(birthMonthVar));
				const noOfDays = new Array(days).fill('');
				setDaysInMonth(noOfDays);
				setBirthDate(Number(partialDob.slice(2)));

				dispatch(updateFirstName(firstName));
				dispatch(updateLasttName(lastName));
				dispatch(updateSSN(partialSSN1 + partialSSN2));
				dispatch(updateLoading(false));
			
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};
	useEffect(() =>  {

		let isRehireEligible = true;
		
		if(aboutYou.applInfo !== undefined)
		{
			if (
				aboutYou.applInfo.firstName &&
				aboutYou.applInfo.firstName !== '' &&
				aboutYou.applInfo.phoneNumber &&
				aboutYou.applInfo.phoneNumber !== ''
			) {
				for (let i = 0; i < aboutYou.applInfo.hcTestResponse.length; i++) {
					if (aboutYou.applInfo.hcTestResponse[i].questionNbr === 45 && aboutYou.applInfo.hcTestResponse[i].answerNbr === 0)
						isRehireEligible = false;
				}
				if (isRehireEligible)
					personalInfoComplete();
				else
					setRehireStatus(true);

			}
		}
	}, [aboutYou.applInfo])

	const resetRehireStatus = (rehirestatus) => {
		setRehireStatus(rehireStatus);

	}

	const handlePreEmployment = (value) => {
		let ans;
		if (value.yes) {
			ans = 'Y';
		} else {
			ans = 'N';
		}
		const requestInput = {
			formerAssociate: ans,
			applicantId: global.applicantId,
			lastName: lastName,
			partialDob:
				'' +
				(birthMonth.length === 1 ? '0' + birthMonth : birthMonth) +
				(('' + birthDate).length === 1 ? '0' + birthDate : birthDate),
			partSsnNbr: partialSSN1 + partialSSN2,
			zipCode: zipCode,
			applStatusCode: 0,
			fromExpPage : false
		};

		dispatch(updateLoading(true));
		dispatch(checkRehire(requestInput))
			.then((res) => {
				if (res.payload.responseCode === '201' || res.payload.responseCode === '200') {
					setRehireStatus(false);
					setSuccess(true);
					props.handleStudentScreen(true);
					scroll.scrollToBottom();
				} else if (res.payload.responseCode === '300') {
					const responseText = res.payload.responseMessage.split('#@#');
					if (global.language === 'ES') {
						setExitText(responseText[2]);
					} else {
						setExitText(responseText[1]);
					}
					setRehireStatus(false);
					setExit(true);
				}

				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleExit = (value) => {
		setExit(value);
		dispatch(onLogOut());
	};

	const handleClose = () => {
		setSsnDisclaimerPopUp(false);
	}

	moment.locale(global.language === 'ES' ? 'es' : 'en');
	const months = moment.months();

	const dobFlex = isPortrait && global.language === 'ES' ? 'column' : 'row';
	const dobWidth = isPortrait && global.language === 'ES' ? '100%' : '48%';
	const zipWidth = !isPortrait && global.language === 'ES' ? '62%' : '100%';

	return (
		<FormGroup>
			<AlertDialog
							isOpen={ssnDisclaimerPopUp}
							onClose={handleButton}
							title={t('location.notice')}
							// children={<Body>{t('minimumQualifications.alertToProceed')}</Body>}
							>
							<Body as="div" size="medium" weight={400}>
								{t('aboutYouPersonalInfo.preEmploymentText')}
							</Body>				
						</AlertDialog>
			<div className={style.page}>
				<div className={style.headingStyle}>
					<Body as="p" size="large" weight={700}>
						{t('aboutYouPersonalInfo.title')}
					</Body>
				</div>
				<Body as="div" size="medium" weight={400}>
					{t('general.mandatoryText')}
				</Body>
				<div className={style.legalName}>
					<TextField
						type="text"
						name="firstName"
						textFieldProps={{
							maxLength: 15
						}}
						label={t('aboutYouPersonalInfo.firstName')}
						onChange={(e) => handleFirstName(e)}
						value={firstName}
					/>

					<div className={style.middleInitial}>
						<TextField
							type="text"
							name="middleName"
							label={t('aboutYouPersonalInfo.middleInitial')}
							textFieldProps={{
								maxLength: 1
							}}
							onChange={(e) => handleMiddleInitial(e)}
							value={middleInitial}
						/>
					</div>

					<TextField
						type="text"
						name="lastName"
						textFieldProps={{
							maxLength: 20
						}}
						label={t('aboutYouPersonalInfo.lastName')}
						onChange={(e) => handleLastName(e)}
						value={lastName}
					/>
				</div>
				<div className={style.preferredName}>
					<TextField
						type="text"
						name="preferedFirstName"
						textFieldProps={{
							maxLength: 15
						}}
						label={t('aboutYouPersonalInfo.preferedFirstName')}
						onChange={(e) => handlePrefferedFirstName(e)}
						value={prefferedFirstName}
					/>

					<TextField
						type="text"
						name="preferedLastName"
						textFieldProps={{
							maxLength: 20
						}}
						label={t('aboutYouPersonalInfo.preferedLastName')}
						onChange={(e) => handlePrefferedLastName(e)}
						value={prefferedLastName}
					/>
				</div>

				<div className={style.zipDoB}>
					<TextField
						type="search"
						name="zip"
						label={t('aboutYouPersonalInfo.zipCode')}
						textFieldProps={{
							maxLength: 5
						}}
						onChange={(e) => handleZipCode(e)}
						value={zipCode}
						error={zipError !== '' ? t(zipError) : ''}
						UNSAFE_style={{ width: zipWidth }}
					/>

					<div style={{ width: '100%', display: 'flex', gap: '16px', flexDirection: dobFlex }}>
						<Select
							label={t('aboutYouPersonalInfo.bithMonth')}
							value={birthMonth}
							style={{ width: dobWidth, float: 'left' }}
							role="combobox"
							aria-haspopup="listbox"
							onChange={(e) => {
								handleBirthMonth(e);
							}}>
							<option value="" aria-hidden={true}></option>
							{months.map((val, index) => {
								return (
									<option
										key={index}
										value={index + 1 >= 10 ? index + 1 : '0' + (index + 1)}
										aria-label={val}>
										{val}
									</option>
								);
							})}
						</Select>

						<Select
							label={t('aboutYouPersonalInfo.birthDay')}
							value={birthDate}
							style={{ width: dobWidth, float: 'right' }}
							role="combobox"
							aria-haspopup="listbox"
							onChange={(e) => {
								handleBirthDay(e);
							}}>
							<option value="" aria-hidden={true}></option>
							{daysInMonth.map((key, index) => {
								return (
									<option key={index} aria-label={index + 1}>
										{index + 1}
									</option>
								);
							})}
						</Select>
					</div>
				</div>
				<div className={style.ssn}>
					<div className={style.fieldName}>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.ssnText')}
						</Body>
					</div>

					<div
						style={{
							width: '100%',
							flexDirection: 'row',
							justifyContent: 'space-between',
							display: 'flex',
							alignItems: 'center',
							paddingTop: '8px'
						}}>
						<div className={style.ssnDot} />
						<div className={style.ssnDot} />
						<div className={style.ssnDot} />

						<div className={style.ssnField__ssnLine}>
							<div className={style.ssnField__lineHorizontal} />
						</div>
						<div className={style.ssnField1}>
							<TextField
								name="ssn1"
								ref={ssn1Ref}
								textFieldProps={{
									maxLength: 2,
									inputMode: 'numeric'
								}}
								onChange={(e) => handlessn1(e)}
								value={partialSSN1}
								disabled={ssnDisabled}
								type={maskSsn ? 'password' : 'text'}
							/>
						</div>
						<div className={style.ssnField__ssnLine}>
							<div className={style.ssnField__lineHorizontal} />
						</div>
						<div className={style.ssnField2}>
							<TextField
								name="ssn2"
								ref={ssn2Ref}
								textFieldProps={{
									maxLength: 4,
									inputMode: 'numeric'
								}}
								onChange={(e) => handlessn2(e)}
								value={partialSSN2}
								disabled={ssnDisabled}
								type={maskSsn ? 'password' : 'text'}
							/>
						</div>
						<div style={{ paddingLeft: '8px' }}>
							{maskSsn ? (
								<Eye size="medium" onClick={() => setMaskSsn(false)} />
							) : (
								<EyeSlash size="medium" onClick={() => setMaskSsn(true)} />
							)}
						</div>
					</div>
				</div>
				{rehireStatus && (
					<PreEmployMentModal
						resetRehireStatus = {resetRehireStatus}
						rehireStatus={rehireStatus}
						handleModal={handleModal}
						handlePreEmployment={handlePreEmployment}
						potentailMatchStoreNbr = {potentialMatchStoreNbr}
						handleDisableButton={props.handleDisableButton}
					/>
				)}
				{exit && <ExitModal exit={exit} handleExit={handleExit} exitText={exitText} />}
				<div>
					<Checkbox
						label={t('aboutYouPersonalInfo.ssnMessage')}
						onChange={(e) => handleCheckBox(e)}
						checked={checkBox}
						disabled={ssnDisabled}
					/>
				</div>

				{!success ? (
					<div>
						<Button
							data-testid="aboutYouPersonalInfo_next"
							size="small"
							variant="primary"
							disabled={
								!firstName ||
								firstName === '' ||
								!lastName ||
								lastName === '' ||
								zipCode.trim() === '' ||
								zipCode.length !== 5 ||
								!checkBox ||
								partialSSN1 === '' ||
								partialSSN2 === '' ||
								(partialSSN1 + partialSSN2).length < 6 ||
								birthDate === 0 ||
								birthDate === '' ||
								birthMonth === 0 ||
								birthMonth === ''
							}
							onClick={handleDisclaimer}>
							{t('aboutYouPersonalInfo.next')}
						</Button>
					</div>
				) : (
					<div style={{ width: '100%' }}>
						<div className={style.successLogo}>
							<Check size="small" />
						</div>

						<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#1D5F02' }}>
							{t('aboutYouPersonalInfo.complete')}
						</Body>
					</div>
				)}
			</div>
		</FormGroup>
	);
};

export default AboutYouPersonalInfo;
