import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStepMapper } from 'pages/VirtualJobOffer/hooks/useStepMapper';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';

/**
 * Custom hook to handle dashboard logics
 * @returns isActive: true if the step is active
 * @returns isFinished: true if the step is completed
 * @returns isNotStarted: true if the step is not started
 */
export const useDashboard = (offerStep) => {
	const { getPageFromStep: page } = useStepMapper();
	const { drugScreenRequiredResponse } = useDrugScreenInfo();
	const navigate = useNavigate();
	const isActive = useMemo(() => {
		return page === offerStep?.step;
	}, [offerStep, page]);

	const isFinished = useMemo(() => {
		return page > offerStep?.step;
	}, [offerStep, page]);

	const isNotStarted = useMemo(() => {
		return !isActive && !isFinished;
	}, [isActive, isFinished]);

	const onStepClick = useCallback(
		(step) => {
			if (!isNotStarted && (typeof drugScreenRequiredResponse?.drugScreenRequired === 'boolean' || typeof drugScreenRequiredResponse?.fullSsnAvailable === 'boolean')) {
				navigate('../vjo-steps', {
					state: {
						step: step,
						activePageFromDashboard: page,
						isDrugScreenRequired: drugScreenRequiredResponse?.drugScreenRequired,
						isFromLoginPage: false,
						fullSsnAvailable: drugScreenRequiredResponse?.fullSsnAvailable,
						drugScreenStatus: drugScreenRequiredResponse?.drugScreenStatus
					}
				});
			}
		},
		[isNotStarted, navigate, drugScreenRequiredResponse]
	);

	return {
		isActive,
		isFinished,
		isNotStarted,
		onStepClick
	};
};
