import React, {memo, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useVirtualJobOffer} from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import {useTranslation} from 'react-i18next';
import {useDisplay} from 'utils/useDisplay';
import {Heading, StyledText, Body} from '@walmart-web/livingdesign-components';
import './styles.scss';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';
import { useVJOSteps } from 'pages/VirtualJobOffer/hooks/useVJOSteps';

const StepHeader = ({currentStep}) => {
  const {account, isSupplyChain} = useVirtualJobOffer();
  const {t} = useTranslation();
  const {isPortrait} = useDisplay();
  const { drugScreenRequiredResponse } = useDrugScreenInfo();
  const { handleStepsUpdate } = useVJOSteps();
  const [offerSteps, setOfferSteps] = useState(t('vjoDashboard.pagesWithoutDS', {returnObjects: true}));

  useEffect(() => {
    setOfferSteps(handleStepsUpdate());
  },[drugScreenRequiredResponse, t]);

  // Return title based on the current step prop
  const title = useMemo(() => {
    const offer = Array.isArray(offerSteps) && offerSteps.find((offerStep) => {
      return offerStep?.step === currentStep;
    });
    if (!offer) {
      return isPortrait
        ? t('vjoCongratulations.congrats')
        : t('vjoCongratulations.congratulations', {
            applicantName: account?.data?.auth?.applicantName,
          });
    }
    return !isPortrait ? `${t('virtualJobOffer.step')} ${offer.step}: ${isSupplyChain ? offer.scvalue : offer.value}` : (isSupplyChain ? offer.scvalue : offer.value);
  }, [t, isPortrait, account, currentStep, offerSteps, isSupplyChain]);

  // Portrait View
  const PortraitView = useMemo(() => {
    return (
      <StyledText
        children={
          <Body
            children={title}
            size='medium'
            weight={700}
            UNSAFE_className='vjo_step_header_text'
          />
        }
        size='small'
        UNSAFE_className='vjo_steps_header_joboffer'
      />
    );
  }, [title]);

  // Desktop view
  const DesktopView = useMemo(() => {
    return (
      <>
          <div className='vjo_steps_header_joboffer' />
        <Heading children={title} UNSAFE_className='vjo_steps_header_title' />
        <div className='vjo_steps_header_joboffer' />
      </>
    );
  }, [t, title, currentStep]);

  return isPortrait ? PortraitView : DesktopView;
};

StepHeader.prototypes = {
  currentStep: PropTypes.number.isRequired,
};
export default memo(StepHeader);
