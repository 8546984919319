export const STEPS = {
  CONTACT_INFO: 0,
  AVAILABILITY: 1,
  REVIEW: 2,
  ACKNOWLEDGE: 3,
  ACKNOWLEDGE_DECLINED: 4,
  DRUG_SCREEN_CHECK: 5,
  BACKGROUND_CHECK_WITH_DS_REQUIRED: 6,
  BACKGROUND_CHECK_WITHOUT_DS_REQUIRED: 5,
  CONGRATULATIONS_WITH_DS_REQUIRED: 7,
  CONGRATULATIONS_WITHOUT_DS_REQUIRED: 6,
};

export const PAGES = {
  CONTACT_INFO: 1,
  AVAILABILITY: 2,
  REVIEW: 3,
  ACKNOWLEDGE: 4,
  DRUG_SCREEN_CHECK: 5,
  BACKGROUND_CHECK: 6,
  CONGRATULATIONS: 7
}

export const TOTAL_STEPS_WITH_DS = 6;
export const TOTAL_STEPS_WITHOUT_DS = 5;

